#eventitem {
  .box {
    background-color: hsl(0, 0%, 100%);
    border-radius: 6px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
    color: hsl(0, 0%, 29%);
    display: block;
    padding: 1.25rem;
  }

  .media-left {
    @media screen and (max-width: 780px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .event_date .d-flex.flex-column {
    background: $mainColor;
    color: #ffffff;
    width: 80px;
    height: 80px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }

  .image {
    width: 100%;

    @media screen and (max-width: $mobile) {
      margin: auto;
      width: 300px;
    }
  }

  .event_content {
    padding: 2rem;
  }

  h2 a {
    color: #4a4a4a !important;
  }

  li {
    color: $mainColor;
    font-size: 0.93rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding-right: 12px;
    position: relative;

    .icon {
      margin-right: 5px;
    }

    &:not(:last-of-type)::after {
      content: "";
      right: 0;
      top: 50%;
      position: absolute;
      width: 1px;
      height: 55%;
      display: block;
      transform: translateY(-50%);
      background-color: #e9e9e9;
    }
  }
}
