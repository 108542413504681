#banner.ep-workshop_header {
  position: relative;

  .is-overlay {
    width: 100%;
    height: 30vh;
    background-color: rgba(14, 14, 14, 0.65);
  }

  .about-header_deatils {
    position: absolute;
    width: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  h3 {
    color: #ffffff;
    font-size: 1.2rem;
  }
}
