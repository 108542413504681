.black--bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $mainColor;
  display: none;

  &.active {
    display: block;
  }
}
