#PrivacyPolicy {
  position: relative;

  main {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0 0 10px #cdcdcd;
    padding: 0 4rem;

    &:hover {
      box-shadow: 0 0 15px #cdcdcd;
    }
  }
}
