#products {
  position: relative;

  .card {
    border: none;
    position: relative;
    transition: all 0.3s linear;
    cursor: pointer;

    .pr-hero {
      position: relative;

      .product-badges {
        position: absolute;
        left: 1.25rem;
        top: 1.25rem;
        background-color: #84c9ac;
        width: fit-content;
      }
    }

    &:hover .pr-hero::before {
      opacity: 1;
    }

    .card-img {
      width: 100%;
      height: 350px;
      border-radius: none;
    }

    .card-body,
    .card-img {
      transition: all 0.3s linear;
    }

    .card-body {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
    }

    .pr-title {
      font-size: 18px;
      color: $blackColor;
      font-weight: 300;

      &:hover {
        color: #f00;
      }
    }

    .prod-button {
      color: #a1a1a1;
      font-size: 18px;
      svg {
        padding-right: 10px;
        color: green;
      }
    }

    .pr-price {
      color: $blackColor;
      font-weight: 500;
      font-size: 18px;
    }

    &:hover .card-body {
      opacity: 1;
    }

    .price {
      color: rgba(235, 235, 235, 1);
    }
  }

  button {
    background-color: transparent;
  }

  .page-link.active,
  .active > .page-link {
    background-color: $mainColor;
    border-color: $mainColor;
    color: #fff !important;
  }
}

#products {
  aside {
    position: relative;
    transition: all 0.3s linear;
    background-color: #fff;
    height: 100%;

    @media screen and (max-width: 767px) {
      padding: 1rem 30px;
    }

    @media screen and (max-width: 500px) {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
    > ul.mb-5.p-0 {
      @media screen and (max-width: 500px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      > .link {
        @media screen and (max-width: 500px) {
          padding-left: 20px;
        }
      }
    }

    .media-icons {
      @media screen and (max-width: 500px) {
        justify-content: center;
      }
    }
  }

  form {
    border: 1px solid $mainColor;

    input {
      padding: 9px 10px;
      color: $blackColor;
      font-size: 15px;
    }

    .icon {
      background-color: $mainColor;
      cursor: pointer;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }

  .item_count {
    background: #e6e6e6;
    border-radius: 50px;
    padding: 0.5em 0.8em;
    font-size: 0.65em;
    line-height: 1.1em;
    color: #5f5f5f;
    display: inline-block;
    vertical-align: middle;
    margin-top: -0.1em;
    margin-left: 3px;
  }
}
