.toast {
  padding: 1.2em;
  position: fixed;
  top: 30px;
  left: 30px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  .success {
    width: fit-content;
    background-color: greenyellow;

    .msg {
      font-size: 18px;
      font-weight: 450;
      font-style: italic;
      color: #fff;
    }
  }
}
