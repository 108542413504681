.default-padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.default-padding-top {
  padding-top: 4rem;
}

.default-margin {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.default-margin-top {
  margin-top: 4rem;
}

.pb-50 {
  padding-bottom: 1rem;
}

.display-flex-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link {
  cursor: pointer;
}
