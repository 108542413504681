#about {
  .description {
    font-weight: 400;
    font-size: 0.95rem;
    color: #4a4a4a;
    line-height: 1.7;
  }

  .button {
    background-color: transparent !important;
    color: $mainColor !important;
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    border: 1px solid $mainColor;
    padding: 10px 20px;
    transition: all 0.3s linear;
    border-radius: 4px;

    &:hover {
      background-color: $mainColor !important;
      color: $whiteColor !important;
    }
  }
}
