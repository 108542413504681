#gallery {
  position: relative;

  .product-item {
    margin-top: 10px;
    margin-left: 10px;
    overflow: hidden;
    height: 250px;
    box-shadow: 0 0 3px #cdcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $mainColor;
    }

    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      span {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $whiteColor;
      }
    }
  }
}
