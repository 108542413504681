#categories {
  position: relative;

  .cate-card {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;

    img {
      overflow: hidden;
      border-radius: 8px;
      width: 100%;
      height: auto;
      transition: all 0.3s linear;
    }

    &:hover img {
      transform: rotate(2deg) scale3d(1.5, 1.5, 1.5);
      filter: brightness(0.5);
    }

    &:hover .content {
      opacity: 1;
    }
  }

  .cate-card figure::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
  }

  .cate-card figure:hover::before {
    -webkit-animation: shine 0.75s;
    animation: shine 0.75s;
  }

  @-webkit-keyframes shine {
    100% {
      left: 125%;
    }
  }

  @keyframes shine {
    100% {
      left: 125%;
    }
  }

  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 60px;
    padding: 10px 0;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    .card-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      color: $whiteColor;
      text-transform: capitalize;
      font-weight: 700;
      letter-spacing: 1px;

      @media screen and (max-width: 1200px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 500px) {
        font-size: 19px;
      }
    }
  }
}
