// GOOGLE FONTS
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;500;700&display=swap");

@font-face {
  font-family: aAutoSignature;
  src: url("../../assets/fonts/aAutoSignature.otf");
}

// h1 {
//   font-family: aAutoSignature, sans-serif !important;
// }
