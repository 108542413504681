#background-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000000;
  z-index: 9999;
  display: none;

  &.active {
    display: block;
  }
}
