#workshop {
  .works {
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;

    .card {
      width: 100%;

      .image {
        @media screen and ($mobile) {
          width: 300px;
          margin: auto;
        }
      }

      .card-text {
        color: $blackColor;
        font-size: 18px;
      }
    }
  }

  .button {
    background-color: transparent !important;
    color: $mainColor !important;
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    border: 1px solid $mainColor;
    border-radius: 4px;
    padding: 20px 30px;
    transition: all 0.3s ease;

    &:hover {
      background-color: $mainColor !important;
      color: $whiteColor !important;
    }
  }
}
