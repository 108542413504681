.mobile-nav {
  // position: fixed !important;
  // left: 0;
  // top: 0;
  // width: 39%;
  // height: 100%;
  // background-color: #fcfcfc;
  // padding: 30px 20px;

  .brand-logo {
    width: 180px;
    height: auto;
  }

  .nav-items {
    display: grid;
    padding: 2rem 0;

    .nav-item {
      padding: 30px 0;
      text-decoration: none;
      color: $mainColor;
      font-size: 18px;
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

      &:hover {
        letter-spacing: 1px;
      }

      &.active,
      &:active {
        letter-spacing: 1px;
        font-weight: 700;
      }
    }
  }

  display: none;

  &.active {
    display: block;
  }
}
