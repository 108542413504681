.mobile-tabs {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  @media screen and (max-width: 500px) {
    display: block;
  }

  .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    text-align: center;

    a {
      font-size: 15px;
    }
  }
}
