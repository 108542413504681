#hero {
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;

  .overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 50%);
    z-index: 1;
  }

  .hero-img {
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.117);
      width: 100%;
      height: 100%;
    }
  }

  .hero-content {
    z-index: 10;
    position: absolute;
    left: 35%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    overflow: hidden;
    text-align: left;

    @media screen and (max-width: 600px) {
      text-align: center;
      left: 50% !important;
      width: 84% !important;
    }

    .button.colored {
      width: fit-content !important;
      @media screen and (max-width: 600px) {
        margin: auto !important;
      }
    }

    h1 {
      text-transform: capitalize;
      font-size: 40px;

      &.active {
        animation: fadeIn 0.4s ease-in;
      }
    }

    p {
      font-size: 20px;

      &.active {
        animation: fadeIn 0.3s ease-in;
      }
    }

    button {
      animation: fadeIn 0.2s ease-in;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  img:not([src]):not([srcset]) {
    visibility: hidden;
  }

  .bg {
    position: absolute;
  }
}
