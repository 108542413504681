* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body,
html {
  overflow-x: hidden;
}

figure {
  margin: 0 0 0 !important;
}

button,
input {
  border: none;
  outline: none;
}

a,
.link {
  text-decoration: none !important;
  color: $mainColor !important;
}

img {
  width: 100%;
  height: auto;
}

.button {
  background-color: transparent;
  color: $mainColor;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  border: 1px solid $mainColor;
  padding: 10px 30px;
  transition: all 0.3s linear;
  border-radius: 4px;

  &.colored {
    background-color: $mainColor;
    color: #fff;
  }

  &:hover {
    background-color: $mainColor !important;
    color: $whiteColor !important;
  }

  @media screen and (max-width: 780px) {
    width: 100%;
  }
}

.title {
  text-transform: capitalize;
}

.btn-custom {
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: $mainColor;
  border: 1px solid $mainColor;
  column-gap: 10px;
  padding: 14px 2rem;

  &:hover {
    color: #121a25;
    border: 1px solid $mainColor;
  }
}

$mobile: 992px;

.buttons {
  .media-icons {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    .icon {
      padding: 10px 30px;
      border: 1px solid #121a25;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

      svg {
        font-size: 18px;
      }

      &:hover {
        background-color: $mainColor;
        color: #fff;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: $mainColor;
  box-shadow: 0 0 10px $mainColor;
}

.downloads {
  background: #cdcdcd;
  width: 100%;
  height: 10px;
  position: relative;
  overflow: hidden;
  .blue-line {
    background: $mainColor;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100px;
    height: 10px;
    animation: line-bounce 3s infinite;
    box-shadow: 0 0 10px $mainColor;

    @keyframes line-bounce {
      0% {
        left: 100%;
      }

      50% {
        left: 0;
      }

      100% {
        left: 100%;
      }
    }
  }
  .blue-line-two {
    background: $mainColor;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100px;
    height: 10px;
    animation: line-bounce-two 3s infinite ease;
    box-shadow: 0 10px 10px #004883;

    @keyframes line-bounce-two {
      0% {
        left: 100%;
      }

      50% {
        left: 0;
      }

      100% {
        left: 100%;
      }
    }
  }
}
