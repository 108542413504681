#header {
  background-color: $mainColor;
  padding: 10px 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-link {
      text-transform: capitalize;
    }

    .nav-item {
      padding-left: 15px;
    }

    .icon {
      width: 19px;
    }

    p {
      font-size: 14px;
      color: #fdfdfd;
      font-weight: 400;
    }

    .left {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        right: -14px;
        width: 1px;
        height: 100%;
        background-color: #fff;

        @media screen and (max-width: 465px) {
          display: none;
        }
      }
    }

    .right-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      @media screen and (max-width: 465px) {
        flex-direction: column;
        gap: 0px;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}
