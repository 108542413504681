#portfolio {
  .fj-gallery {
    .fj-gallery-item {
      cursor: pointer;
      overflow: hidden;

      img {
        transition: all 0.3s ease-in-out;
      }

      &:hover img {
        transform: scale(1.1) rotate(2deg);
      }
    }
  }

  .nav-item {
    .nav-link {
      color: $blackColor;
    }
  }
}
