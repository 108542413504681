#reviews {
  background: url(../../assets/images//hero/3.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;

  .background-overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 45%);
  }

  .reviews {
    margin: auto;

    .quoteIcon {
      color: $mainColor;
      text-align: center;
      font-size: 2rem;
      height: 64px;
      width: 64px;
      border: 1px solid $mainColor;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: $mainColor;
      }
    }

    .w-80 {
      width: 80%;

      @media screen and (max-width: $mobile) {
        width: 75% !important;
      }
    }

    h5 {
      font-size: 16px;
      position: relative;
      width: fit-content;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 110%;
        transform: translate(-50%, -50%);
        background-color: $mainColor;
        width: 80%;
        height: 1px;
      }
    }
  }
}
