#breadcrumb {
  background: #fff5ea;

  h1 {
    font-size: 2.7rem !important;
  }

  p {
    padding: 10px 0;
    color: $blackColor;
    font-size: 18px;
    text-transform: capitalize;
  }
}
