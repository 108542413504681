#search {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: 100%;
  height: 100dvh;
  cursor: url(../../assets/images/close.png), auto;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: none;

  &.active {
    display: block;
  }

  .background {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(141, 90, 31, 0.493);
    height: 100%;
    width: 100%;
    animation: fullWith 0.6s linear;
    border-radius: 0;
    backdrop-filter: blur(5px);
    animation: raduisBG 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .input-group {
    transition: all 1s ease-in;
    background-color: transparent;
    font-size: 20px;
    color: #121a25;
    font-weight: 500;
    letter-spacing: 1px;
    position: absolute;
    left: 50%;
    width: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    overflow: hidden;

    &.active {
      animation: fullWithInput 1.4s linear;
    }

    input {
      &::placeholder {
        color: #cdcdcd;
        font-style: italic;
        font-size: 15px;
      }

      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      cursor: text;
      width: calc(100% - 60px);
      padding: 1.4rem 0;
      text-indent: 30px;
      letter-spacing: 1px;
      font-style: italic;
    }

    .searchBarICON {
      background-color: $whiteColor;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@keyframes raduisBG {
  0% {
    border-radius: 50%;
    height: 0%;
    width: 0%;
    opacity: 0;
  }

  50% {
    height: 60%;
    width: 60%;
    opacity: 1;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 1;
    height: 100%;
    width: 100%;
    border-radius: 0%;
  }
}

@keyframes fullWithInput {
  0% {
    opacity: 0;
    width: 0%;
  }

  50% {
    opacity: 1;
    width: 30%;
  }

  100% {
    opacity: 1;
    width: 60%;
  }
}

@keyframes fullWith {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
