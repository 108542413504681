#notfound {
  position: relative;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    span {
      font-size: 80px;
      margin-bottom: -14px;
      color: $mainColor;
      -webkit-text-fill-color: white;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: $mainColor;
      text-shadow: 3px 3px 0 $mainColor, -1px -1px 0 $mainColor,
        1px -1px 0 $mainColor, -1px 1px 0 $mainColor, 1px 1px 0 $mainColor;
    }

    h2 {
      line-height: 100%;
      font-size: 74px;
      font-weight: 900;
    }

    .button {
      background-color: transparent !important;
      color: $mainColor !important;
      display: flex;
      align-items: center;
      gap: 10px;
      width: fit-content;
      border: 1px solid $mainColor;
      transition: all 0.3s linear;
      padding: 10px 20px;
      border-radius: 4px;

      &:hover {
        background-color: $mainColor !important;
        color: $whiteColor !important;
      }
    }
  }
}
