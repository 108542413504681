#contact {
  .contact-information {
    // padding: 2rem;
    background-color: #fff;
    // display: flex;
    // justify-content: center;
    // flex-direction: column;
  }

  .contact-map {
    backdrop-filter: blur(8px);
    border-radius: 8px;
  }

  .contact-form {
    backdrop-filter: blur(8px);
    background-color: #fff;
    padding-bottom: 4rem;
  }

  .form-control {
    padding: 1rem 0.75rem;
  }

  .form-control::placeholder {
    color: #dadada;
    font-style: italic;
    font-size: 14px;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.25rem transparent;
  }

  .btn-custom {
    width: 100% !important;

    span {
      text-align: center;
    }
  }

  .btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
