.ril__navButtonNext,
.ril__navButtonPrev {
  border-radius: 11px !important;
  box-shadow: 0 0 5px #fff !important;

  &:active {
    background-color: $mainColor;
  }
}

.ril__navButtonNext {
  right: 28px !important;
}

.ril__navButtonPrev {
  left: 28px !important;
}

img.ril-image-current.ril__image {
  width: 800px !important;
  position: absolute !important;
  left: 0% !important;
  top: 0% !important;
  transform: translate(0, 0) !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: $whiteColor;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: $whiteColor;
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.swiper-button-next:after,
.swiper-ltr .swiper-button-next:after {
  content: "next";
  font-size: 30px !important;
  color: $whiteColor;
  font-weight: 400;
}

.swiper-button-prev:after,
.swiper-button-prev:after {
  content: "prev";
  font-size: 30px !important;
  color: $whiteColor;
  font-weight: 400;
  padding: 30px !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: transparent !important;
  border-radius: 4px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: $whiteColor;

  &:active,
  &:hover {
    background-color: $mainColor !important;
  }
}

.swiper-button-next {
  box-shadow: 0 0 5px #ffffff;
  margin-right: 2rem;

  @media screen and (max-width: 780px) {
    margin-right: 0;
  }
}

.swiper-button-prev {
  box-shadow: 0 0 5px #ffffff;
  margin-left: 2rem;

  @media screen and (max-width: 780px) {
    margin-left: 0;
  }
}

.swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  background-color: #1f2738 !important;
  display: inline-block !important;
  opacity: 0.5 !important;
  border: none !important;
  margin-bottom: 10px !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: $whiteColor !important;
}
