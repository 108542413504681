#wa {
  .wa-icon {
    width: 93px;
    position: fixed;
    left: 20px;
    bottom: 20px;
    padding: 30px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s linear;

    &.active {
      opacity: 1;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: rgb(45, 183, 66);
        border-radius: 50%;
        padding: 30px;
        z-index: -1;
      }
    }
  }

  .wa-txt {
    width: fit-content;
    position: fixed;
    left: -123px;
    bottom: 26px;
    padding: 13px 24px;
    z-index: 10;
    text-align: center;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 0 7px #cdcde1;
    transition: all 0.8s ease-in;
    cursor: pointer;
    opacity: 0;

    &.active {
      left: 105px;
      opacity: 1;
    }

    @media screen and (max-width: 780px) {
      display: none;
    }
  }
}
