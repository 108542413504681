.navbar {
  padding: 20px 4rem;
  background-color: #fff;
  transition: all 0.3s ease-in;
  z-index: 9999;
  position: relative;

  &.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 10px #cdcdcd;
  }

  @media screen and (max-width: $mobile) {
    padding: 20px 2rem;
  }

  .brand-logo {
    width: 180px;
    height: auto;
  }

  .searchBarICON {
    cursor: pointer;
    width: 40px;
    padding: 8px;
    text-align: center;
  }

  .drop-down-menu {
    position: absolute;
    top: 4rem;
    left: 19px;
    background-color: rgb(255, 255, 255);
    border: 1px solid;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    z-index: 9999;
    padding: 30px 2rem;
    visibility: hidden;
    border-radius: 4px;
    display: none;

    &.active {
      visibility: visible;
      display: block;
      height: fit-content;
    }
  }

  .position-relative {
    .menu-langs {
      position: absolute;
      left: 0;
      top: 175%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 10;
      display: flex;
      flex-direction: column;
      gap: 8px;
      display: none;
      border-radius: 3px;
      padding: 20px;
      box-shadow: 0 0 10px #ede7e7;

      .menu-langs-title {
        color: rgba(0, 0, 0, 0.54);
        position: relative;
        width: 100%;
        margin-bottom: 5px !important;
        font-size: 12px;
        margin: 10px 0;
      }

      .lan {
        position: relative;
        padding: 5px 0;
        cursor: pointer;

        span {
          font-size: 20px;
        }

        > img {
          width: 25px !important;
        }
      }

      &.active {
        display: block;
      }
    }
  }

  .settings {
    font-size: 1rem;
    padding: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    padding-left: calc(1rem + 7px);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0.3px;
      height: 100%;
      background-color: $grayColor;
    }
  }

  .nav-items {
    display: inline-block;

    .nav-item {
      padding-left: 25px;
      text-decoration: none;
      font-size: 18px;
      color: $mainColor;
      text-transform: capitalize;
    }

    @media screen and (max-width: 1200px) {
      display: none;
    }

    @media screen and (max-width: $mobile) {
      display: none;
    }
  }

  .icon {
    padding: 8px;
    border: 1px solid $mainColor;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      background-color: $mainColor;
      color: #fff;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 20px;

    .media-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      @media screen and (max-width: $mobile) {
        display: none;
      }
    }
  }

  .bars-icon {
    cursor: pointer;

    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}
