footer {
  ul {
    margin: 0px;
    padding: 0px;
  }
  &.footer-section {
    background-color: #fafafa;
    position: relative;
  }
  .footer-cta {
    border-bottom: 1px solid #373636;
    background-color: $mainColor;
  }
  .single-cta {
    display: flex;
    align-items: center;
  }
  .cta-text {
    padding-left: 15px;
    display: inline-block;
  }
  .cta-text h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .cta-text span {
    color: #fff;
    font-size: 15px;
  }
  .footer-content {
    position: relative;
    z-index: 2;
  }
  .footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
  }
  .footer-logo {
    margin-bottom: 30px;
  }
  .footer-logo img {
    max-width: 200px;
  }
  .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 28px;
  }
  .footer-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }
  .footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
  }
  .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
  }
  .footer-widget-heading h3 {
    color: $blackColor;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
  }
  .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #ff5e14;
  }
  .footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
  }
  .footer-widget ul li a:hover {
    color: #ff5e14;
  }
  button.mb-1.tag {
    padding: 11px 17px;
    margin: 0 3px;
    background-color: $mainColor;
    color: #fff;
  }
  .footer-widget ul li a {
    color: #878787;
    text-transform: capitalize;
  }
  .subscribe-form {
    position: relative;
    overflow: hidden;
  }
  .subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #2e2e2e;
    border: 1px solid #2e2e2e;
    color: #fff;
  }
  .subscribe-form button {
    position: absolute;
    right: 0;
    background: #ff5e14;
    padding: 13px 20px;
    border: 1px solid #ff5e14;
    top: 0;
  }
  .subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
  }
  .copyright-area {
    background: #fff;
    padding: 25px 0;
  }
  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
  }
  .copyright-text p a {
    color: #ff5e14;
  }
  .footer-menu li {
    display: inline-block;
    margin-left: 20px;
  }
  .footer-menu li:hover a {
    color: #ff5e14;
  }
  .footer-menu li a {
    font-size: 14px;
    color: #878787;
  }
  .footer-cta {
    .icon {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: #ffffff;
      justify-content: center;
      display: flex;
      align-items: center;
      position: relative;
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 20px;
      svg {
        color: $mainColor;
        font-size: 1.5rem;
      }
      &::after {
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        width: 72px;
        height: 72px;
        border: 1px solid #ffffff;
        background-color: transparent;
        border-radius: 50%;
      }
    }
  }

  .footer-social-links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    svg {
      font-size: 1rem;
      padding: 18px;
      background-color: $mainColor;
      color: #fffeee;
      border-radius: 50%;
    }
  }
}
